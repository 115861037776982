import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import { ActionButton, ActionButtonText } from 'components/layouts/homepage/buttonsV2'
import { Sub, CardsWrapper, IconBox, Title, CardText, Card, SectionWrapper, InvestorsWrapper, InvestorsTextsWrapper, Text, ImageWrapper, CompaniesWrapper } from 'components/layouts/homepage/investorComponents'
const Image = dynamic(() => import('components/utils/image'), { ssr: false })
const Founders = dynamic(() => import('components/layouts/homepage/founders'))

function Investors ({ isSignedIn }) {
  const investorUrl = isSignedIn ? '/profile' : '/sign-in'
  const busUrl = isSignedIn ? '/profile' : '/sign-in-business'

  return (
    <SectionWrapper>
      <CompaniesWrapper>
        <ImageWrapper>
          <Image
            className="w-100"
            src="homepage2"
            type="svg"
            alt="Invest in Private Tech Companies"
            width="458px"
            height="470px" />
        </ImageWrapper>
        <InvestorsWrapper>
          <Sub>Investors:</Sub>
          <Title data-test="invest">invest in high-growth private technology companies</Title>
          <InvestorsTextsWrapper>
            <Text>
              Investment in private assets is exclusive and thus investors are often unable to invest alongside top-tier VC and growth funds
            </Text>
            <Text>
              Join us to invest in the best performing late-stage European technology companies you love
            </Text>
          </InvestorsTextsWrapper>
          <div className="mt-70">
            <a href={investorUrl}><ActionButton>
              <ActionButtonText>
                Investors
              </ActionButtonText>
            </ActionButton></a>
          </div>
        </InvestorsWrapper>
      </CompaniesWrapper>

      <div className="t-center pt-50">
        <Sub>Existing Shareholders:</Sub>
        <Title>maximize your upside</Title>
        <CardsWrapper>
          <Card>
            <IconBox>
              <img className="flex" src="/s/icons/home-invest1.svg" width="30px" height="30px" alt="Later stage investments" />
            </IconBox>
            <CardText>
              As an early investor, you have the right to take your allocation in later stage rounds but are often unable to do so and leave potential value on the table
            </CardText>
          </Card>
          <Card>
            <IconBox>
              <img className="flex" src="/s/icons/home-invest2.svg" width="32px" height="32px" alt="Later stage funding" />
            </IconBox>
            <CardText>
              We help you to fund your allocation in later stage rounds (series C+) and make more money with no funding requirements from you
            </CardText>
          </Card>
          <Card>
            <IconBox>
              <img className="flex" src="/s/icons/home-invest3.svg" width="26px" height="26px" alt="Liquidity" />
            </IconBox>
            <CardText>
              We also provide tailored liquidity solutions to early stage investors looking to sell their shares.
            </CardText>
          </Card>
        </CardsWrapper>
      </div>

      <div className="flex h-center mt-60">
        <a href={busUrl}><ActionButton c="#0bf">
          <ActionButtonText>
            Shareholders
          </ActionButtonText>
        </ActionButton></a>
      </div>
      <Founders />
    </SectionWrapper>
  )
}

Investors.propTypes = {
  isSignedIn: PropTypes.bool
}

export default memo(Investors)
