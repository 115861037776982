import { useRef, useEffect } from 'react'
import { dequal as deepEqual } from 'dequal'

function useMemoize (value) {
  const ref = useRef()

  if (!deepEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export default function useDeep (callback, dependencies) {
  return useEffect(callback, useMemoize(dependencies))
}
