import styled from '@emotion/styled'

import { TYPOGRAPHY_36, TYPOGRAPHY_24_400, TYPOGRAPHY_58 } from 'theme/theme'

export const Sub = styled.div`
  ${TYPOGRAPHY_36}

  color: #a7a8ad;
`

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 0 35px;
    margin-top: 15px;
  }
`

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  width: 60px;
  height: 60px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: #fff;
`

export const Title = styled.div`
  ${TYPOGRAPHY_58}

  color: #656770;
  font-weight: 700;

  @media (max-width: 767px) {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
  }
`

export const CardText = styled.div`
  ${TYPOGRAPHY_24_400}

  color: #646770;
  font-weight: normal;
  text-align: left;
`

export const Card = styled.div`
  position: relative;
  width: 340px;
  background: #fff;
  border-radius: 40px;
  padding: 70px 50px;
  margin-top: 43px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);

  &:not(:first-of-type) {
    margin-left: 66px;
  }

  @media (max-width: 1199px) {
    &:not(:first-of-type) {
      margin-left: 30px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 60px;

    &:not(:first-of-type) {
      margin-left: unset;
    }
  }
`

export const SectionWrapper = styled.section`
  padding-top: 110px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
`

export const InvestorsWrapper = styled.div`
  max-width: 705px;
  flex: 2;
  margin-left: 80px;
  margin-right: 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1199px) {
    margin-left: 50px;
  }

  @media (max-width: 767px) {
    align-items: center;
    max-width: 365px;
    margin: 0 auto;
  }
`

export const InvestorsTextsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Text = styled.div`
  ${TYPOGRAPHY_24_400}

  display: flex;
  align-items: center;
  width: 250px;
  color: #7f8289;
  text-align: left;
  padding-left: 20px;
  border-left: 1px solid #0bf;
  margin-top: 50px;
  margin-right: 110px;

  &:last-of-type {
    margin-right: unset;
  }

  @media (max-width: 1199px) {
    margin-right: auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
    border-left: unset;
    padding-left: unset;

    &:first-of-type {
      flex-direction: column;

      &::before {
        content: '';
        display: flex;
        width: 33%;
        border-bottom: 1px solid #0bf;
        margin-bottom: 20px;
      }
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: start;
  max-width: 460px;
  width: 100%;
  margin-left: 50px;
  flex: 1;

  @media (max-width: 1199px) {
    max-width: 370px;
  }

  @media (max-width: 767px) {
    margin: 0 110px 30px;
  }
`

export const CompaniesWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`
